.App {
  text-align: center;
  min-width: 100vw;
  min-height: calc(100vh + 2rem);
  margin-top: -2rem;
  padding-top: 2rem;

  background-color: #ccc6c6;

  --text-color: black;
  --button-color: lightgreen;
  --button-hover-color: green;
  --border-color: black;
  --active-day: pink;
  --add-text: forestgreen;
  --add-text-hover: darkolivegreen;
  --color-missing: red;
  --menu-color: rebeccapurple;

  color: var(--text-color);
}

.App.Dark {
  background-color: #383831;

  --text-color: #c4c4c2;
  --button-color: #5e5e5c;
  --button-hover-color: #363634;
  --active-day: #606373;
  --menu-color: #758bff;

}

.App.Christmas {
  background-color: #146B3A;

  --text-color: #F8B229;
  --button-color: #BB2528;
  --button-hover-color: #EA4630;
  --border-color: #BB2528;
  --active-day: #165B33;
  --add-text: rgba(248, 178, 41, 0.5);
  --add-text-hover: rgba(248, 178, 41, 0.7);
}

.App.Pink {
  background-color: #ffb3f4;

  --text-color: #b3179c;
  --button-color: #fa64e4;
  --button-hover-color: #fcc2f4;
  --border-color: #b3179c;
}

.App.KV {
  background-color: #ffe32e;

  --text-color: #ff0000;
  --button-color: #fff536;
  --button-hover-color: #a19902;
  --border-color: #ff0000;
  --active-day: #fffa9e;
  --color-missing: green;
}

.loader {
  height: 100vh;
  margin-top: 10rem;
}

h1 {
  margin-bottom: 0;
}

p {
  font-size: 1.25rem;
  font-weight: bold;
}

.menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.menu-item {
  font-size: 2rem;
  font-weight: normal;
  text-decoration: none;
  margin: 0 1rem;
  color: var(--menu-color);
}

.menu-item:hover,
.menu-item:focus,
.menu-item:active {
  cursor: pointer;
  font-weight: bold;
}

.logout {
  margin: 0;
  color: red;
  font-weight: normal;
}

.logout:hover {
  cursor: pointer;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.button {
  background-color: var(--button-color);
  border: 1px solid var(--button-hover-color);
  border-radius: 10px;
  padding: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  max-width: 50%;
  margin: 1rem;
}

.button:hover {
  background-color: var(--button-hover-color);
  cursor: pointer;
}

.button-delete {
  color: red;
  border: 1px solid red;
}

.wc-alert p {
  color: var(--color-missing);
  font-weight: bold;
  font-size: 2rem;
}

.main {
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  margin: 1rem;
  justify-content: center;
}

.calendar {
  display: flex;
  flex-direction: column;
}

.add-label {
  font-size: 1.5rem;
  margin: 0;
}

.input {
  padding: 1rem;
  font-family: IndieFlower-Regular;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  width: 300px;
}

.select {
  padding: 1rem;
  font-family: IndieFlower-Regular;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  width: 340px;
}

.add-buttons {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.logo {
  max-width: 250px;
  padding-top: 3rem;
}

.photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.image {
  max-height: 50vh;
  max-width: 90vw;
  margin: 1rem;
}

.list-number {
  color: rebeccapurple;
}

.pomp-letter {
  color: red;
  font-size: 1.5rem;
}

.medication {
  display: flex;
  flex-direction: column;
}

.medication textarea {
  width: 80%;
  max-width: 1000px;
  height: 200px;
}

@media screen and (min-width: 1445px){
  .buttons {
    padding: 1rem 4rem;
  }

  .main {
    justify-content: center;
    overflow-x: hidden;
  }

  .calendar {
    flex-direction: row;
    border: 1px solid var(--border-color);
    border-radius: 10px;
  }
}
