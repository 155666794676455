.day-section {
    border-top: 2px solid var(--border-color);
    min-height: 100px;
}

.day-section > div {
    margin-left: 1rem;
    text-align: left;
}

.day-section h2 {
    margin-bottom: 0;
}

.day-section p {
    margin: 0 1rem;
    font-size: 1.25rem;
}

.day-section ul {
    margin: .5rem 0 1rem 0;
}

.day-section li {
    font-size: 1.25rem;
}

.delete {
    color: red;
    padding-left: 1rem;
}

.delete:hover {
    cursor: pointer;
    font-weight: bold;
}
