.day {
    display: flex;
    min-width: 80vw;
    flex-direction: column;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    margin-bottom: 2rem;
}

.day:not(:last-child) {
    border-right: 2px solid var(--border-color);
}

.day.missing {
    color: var(--color-missing);
}

.today {
    background-color: var(--active-day);
}

.day-add {
    font-size: 1rem;
    color: var(--add-text);
}

.day-add:hover {
    cursor: pointer;
    color: var(--add-text-hover);
}

.day-header {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 1.5rem;
}

@media screen and (min-width: 1445px){
    .day {
        min-width: 200px;
        border: none;
        border-radius: 0;
        margin-bottom: 0;
    }
}
