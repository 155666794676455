@font-face {
  font-family: 'IndieFlower-Regular';
  src: local('IndieFlower-Regular'), url(./fonts/IndieFlower-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: IndieFlower-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}
